<template>
  <div class="footer" v-show="!isOnLogoutPage()">
    <div class="rightSideInfo">
      <div>{{this.footerInfo.title}}</div>
        <div>{{this.footerInfo.email}}</div>
        <br>
        <div>{{this.footerInfo.titleSub}}</div>
        <div>{{this.footerInfo.telephone}}</div>
        <br>
        <div>{{this.footerInfo.lastLine}}</div>
      </div>
      <!-- <div class="divider"/> -->
  </div>
</template>

<script>
import FooterInfo from '../data/footerInfo';
import helperMethods from '../mixins/helperMethods.vue';

export default {
  name: 'Footer',
  mixins: [helperMethods],
  data() {
    return {
      footerInfo: FooterInfo,
    };
  },
};
</script>

<style lang="scss" scoped>
.footer {
  background-color: $LincolnGray;
  color: $LincolnGrayLight;
  padding: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  height: 150px;
  position: absolute;
  left: 0;
  right: 0;

  .rightSideInfo > * {
    text-align: left;
  }

  .divider {
    position: absolute;
    border-bottom: 1px solid $LincolnGrayLight;
    width: 91%;
    padding: 20px;
    transform: translateY(63px);
  }
}
</style>
