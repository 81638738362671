<template>
  <div id="app">
    <component :is="layout">
      <router-view/>
    </component>
  </div>
</template>

<script>


export default {
  name: 'app',
  computed: {
    layout() {
      // Return the layout specified in route meta, or default to DefaultLayout if none specified
      return this.$route.meta.layout;
    },
  },

};
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  min-height: 100vh;
}


</style>
