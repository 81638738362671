var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "profileExpanded" }, [
    _c("div", { staticClass: "topBg" }, [
      _c("div", { staticClass: "buttonX", on: { click: _vm.closeMenu } }, [
        _vm._v("X"),
      ]),
      _c("div", { staticClass: "profileGrouping" }, [
        _c(
          "div",
          { staticClass: "profileBadgeFlag", class: _vm.engagementLevelClass },
          [
            _c("EngagementLevelBadge", {
              staticClass: "badge",
              attrs: { badge: _vm.currentEngagementLevel, colored: false },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "profileImgWrapper" },
          [
            _c(
              "router-link",
              {
                staticClass: "circleBorderProfileImg",
                class: _vm.engagementLevelClass,
                attrs: {
                  to: { path: "profile", query: { id: _vm.profile.id } },
                },
              },
              [
                _c("UserImageThumbnail", {
                  class: "imgProfile",
                  attrs: { thumbnailAssetId: _vm.profile.thumbnailAssetId },
                  on: { click: _vm.cleanAndHide },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "txtName firstName" }, [
        _vm._v(_vm._s(this.profile.nameFirst)),
      ]),
      _c("div", { staticClass: "txtName lastName" }, [
        _vm._v(_vm._s(this.profile.nameLast)),
      ]),
    ]),
    _c(
      "div",
      {
        staticClass: "pointsGrouping currentPoints",
        class: { bottomLine: _vm.pointsAway },
      },
      [
        _c("div", { staticClass: "key" }, [_vm._v("Your Current Points")]),
        _c("div", { staticClass: "value" }, [
          _vm._v(_vm._s(this.profile.points)),
        ]),
      ]
    ),
    _vm.pointsAway
      ? _c("div", { staticClass: "pointsGrouping" }, [
          _c("div", { staticClass: "key" }, [
            _vm._v("You are " + _vm._s(_vm.pointsAway) + " away from"),
          ]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.nextLevelName)),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }