var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "imgContainer" }, [
    _c("div", { staticClass: "imgNormal", class: _vm.badgeImage }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }