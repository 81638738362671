import Vue from 'vue';
import VueRouter from 'vue-router';
// eslint-disable-next-line import/no-cycle
import store from './store';

import { setSessionItem } from './utils';

import DefaultLayout from '@/layouts/DefaultLayout.vue';
import NoLayout from '@/layouts/NoLayout.vue';


Vue.use(VueRouter);

function loadView(view) {
  return () => import(`@/views/${view}.vue`);
}

const isAdmin = () => store.getters['userStore/isAdmin'];
const isAuthenticated = () => store.getters['userStore/isAuthenticated'];
const resetState = () => store.dispatch('reset');

const updatePoints = () => store.dispatch('userStore/getPoints');

const loginRedirect = async (to, from, next) => {
  await store.dispatch('userStore/checkLogin');
  if (!isAuthenticated()) {
    return next({ name: 'login', params: { redirectRoute: to } });
  }

  updatePoints();

  return null;
};

const routes = [
  {
    path: '/',
    name: 'home',
    beforeEnter: (to, from, next) => {
      loginRedirect(to, from, next);
      return next();
    },
    component: loadView('Home'),
    meta: { layout: DefaultLayout },
  },
  {
    path: '/EmbeddedPDF',
    component: loadView('EmbeddedPDF'),
    meta: { layout: NoLayout },
  },
  {
    path: '/login',
    name: 'login',
    component: loadView('Login'),
    meta: { layout: DefaultLayout },
  },
  {
    path: '/logout',
    name: 'logout',
    beforeEnter: (to, from, next) => {
      resetState();
      return next();
    },
    component: loadView('Logout'),
    meta: { layout: DefaultLayout },
  },
  {
    path: '/stories',
    name: 'stories',
    props: route => ({ id: route.query.id }),
    beforeEnter: (to, from, next) => {
      loginRedirect(to, from, next);
      return next();
    },
    component: loadView('Stories'),
    meta: { layout: DefaultLayout },
  },
  {
    path: '/mycommunity',
    name: 'community',
    beforeEnter: (to, from, next) => {
      loginRedirect(to, from, next);
      return next();
    },
    component: loadView('Community'),
    meta: { layout: DefaultLayout },
  },
  {
    path: '/resources',
    name: 'resources',
    props: route => ({ id: route.query.id }),
    beforeEnter: (to, from, next) => {
      loginRedirect(to, from, next);
      next();
    },
    component: loadView('Resources'),
    meta: { layout: DefaultLayout },
  },
  {
    path: '/resources/:doctype/:resourceid',
    name: 'resources_resourceid',
    props: route => ({ id: route.query.id }),
    beforeEnter: (to, from, next) => {
      if (!store.getters['userStore/isAuthenticated']) {
        setSessionItem('directlink', to.path);
      }
      loginRedirect(to, from, next);
      next();
    },
    component: loadView('Resources'),
    meta: { layout: DefaultLayout },
  },
  {
    path: '/profile',
    name: 'profile',
    props: route => ({ id: route.query.id }),
    beforeEnter(to, from, next) {
      loginRedirect(to, from, next);
      if (!to.query.id) {
        next('/');
      }
      next();
    },
    component: loadView('Profile'),
    meta: { layout: DefaultLayout },
  },
  {
    path: '/brandStory',
    name: 'brandStory',
    props: route => ({ id: route.query.id }),
    beforeEnter(to, from, next) {
      loginRedirect(to, from, next);
      if (!to.query.id) {
        next('/');
      }

      next();
    },
    component: loadView('BrandStory'),
    meta: { layout: DefaultLayout },
  },
  {
    path: '/admin',
    name: 'admin',
    beforeEnter(to, from, next) {
      loginRedirect(to, from, next);
      if (!isAdmin()) {
        next(from.fullPath);
      } else {
        next();
      }
    },
    component: loadView('Admin'),
    meta: { layout: DefaultLayout },
  },
  {
    path: '/search',
    name: 'search',
    beforeEnter: (to, from, next) => {
      loginRedirect(to, from, next);
      return next();
    },
    component: loadView('Search'),
    meta: { layout: DefaultLayout },
  },
  {
    path: '*',
    name: 'notFound',
    component: loadView('NotFound'),
    meta: { layout: DefaultLayout },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  base: process.env.VUE_APP_BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});

router.afterEach(() => {
  store.dispatch('analyticStore/updateLastNavTime');
});

// Route case-sensitivity hotfix
if (router.mode === 'history') {
  router.history.getCurrentLocation = () => {
    let path = window.location.pathname;
    const { base } = router.history;

    // Removes base from path (case-insensitive)
    if (base && path.toLowerCase().indexOf(base.toLowerCase()) === 0) {
      path = path.slice(base.length);
    }

    return (path || '/') + window.location.search + window.location.hash;
  };
}

export default router;
