var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "searchCompact", on: { click: _vm.clickedIcon } },
    [
      _c("font-awesome-icon", {
        attrs: { icon: ["fal", "search"], size: "2x" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }