var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showSearch,
          expression: "showSearch",
        },
      ],
      staticClass: "search-bar",
      attrs: { id: "search-bar" },
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.searchString,
            expression: "searchString",
          },
        ],
        staticClass: "search-bar-input",
        attrs: { placeholder: "Search" },
        domProps: { value: _vm.searchString },
        on: {
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.search.apply(null, arguments)
          },
          input: function ($event) {
            if ($event.target.composing) return
            _vm.searchString = $event.target.value
          },
        },
      }),
      _c(
        "div",
        { staticClass: "icon", on: { click: _vm.search } },
        [
          _c("font-awesome-icon", {
            attrs: { icon: ["fal", "search"], size: "2x" },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }