var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.isOnLogoutPage(),
          expression: "!isOnLogoutPage()",
        },
      ],
      class: _vm.onHomePage,
    },
    [
      _c("div", { staticClass: "wrapper" }, [
        _c(
          "div",
          { staticClass: "logo" },
          [
            _c(
              "router-link",
              {
                attrs: { to: "/" },
                nativeOn: {
                  click: function ($event) {
                    _vm.clearTenetsAndAreas()
                    _vm.$analytics.trackEventBtnNavBar("Home")
                  },
                },
              },
              [
                _c("LincolnEffortlessExperienceLogo", {
                  staticClass: "imgLogo light",
                }),
                _c("LincolnEffortlessExperienceLogoDark", {
                  staticClass: "imgLogo dark",
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "search" }, [
          _c(
            "div",
            { staticClass: "search-mobile" },
            [_c("SearchMobileContainer")],
            1
          ),
          _c("div", { staticClass: "search-desktop" }, [_c("SearchBar")], 1),
        ]),
        _c("div", { staticClass: "profile" }, [_c("ProfileContainer")], 1),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }