var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "searchExpanded" },
    [
      _c("SearchBar"),
      _c(
        "div",
        { staticClass: "icon" },
        [
          _c("font-awesome-icon", {
            attrs: { icon: ["fal", "arrow-left"], size: "2x" },
            on: { click: _vm.closeSearch },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }