var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    staticClass: "imgLogo",
    style: {
      backgroundImage:
        "url(" + require("../assets/images/logoEffortlessExperience.png") + ")",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }