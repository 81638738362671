var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showProfile,
          expression: "showProfile",
        },
      ],
      staticClass: "profileContainer",
    },
    [
      _c(
        "transition",
        { attrs: { name: "slide-in-top", mode: "out-in" } },
        [
          _c(_vm.toggleProfileView, {
            tag: "component",
            attrs: { profile: _vm.profile },
            on: { menuSwitch: _vm.menuSwitch },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }